import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { withRoomContext } from '../../RoomContext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Flipper, Flipped } from 'react-flip-toolkit';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import * as roomActions from '../../store/actions/roomActions';
import ShutdownDialog from '../ShutdownDialog';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';
import Logger from '../../Logger';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${config.background})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width                          : '60vw',
			padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '80vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '90vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '95vw'
			}
		},
		accountButton :
		{
			padding : 0
		},
		logo :
		{
			maxHeight : 48
		},
		loginButton :
		{
			position : 'absolute',
			right    : theme.spacing(2),
			top      : theme.spacing(2),
			padding  : 0
		},
		largeIcon :
		{
			fontSize : '2em'
		},
		largeAvatar :
		{
			width  : 50,
			height : 50
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		},
		red :
		{
			color : 'rgba(153, 0, 0, 1)'
		},
		username :
		{
			display : 'none'
		},
		list :
		{
			listStyleType   : 'none',
			padding         : theme.spacing(1),
			boxShadow       : '0 2px 5px 2px rgba(0, 0, 0, 0.2)',
			backgroundColor : 'rgba(255, 255, 255, 1)'
		},
		listheader :
		{
			fontWeight : 'bolder'
		},
		listItem :
		{
			width                : '100%',
			overflow             : 'hidden',
			'&:not(:last-child)' :
			{
				borderBottom : '1px solid #CBCBCB'
			}
		},
		listItemRoot :
		{
			width        : '100%',
			overflow     : 'hidden',
			cursor       : 'auto',
			display      : 'flex',
			marginTop    : '5px',
			marginBottom : '5px'
		},
		peerInfo :
		{
			fontSize    : '1rem',
			display     : 'flex',
			paddingLeft : theme.spacing(1),
			flexGrow    : 1,
			alignItems  : 'center'
		},
		joinButton :
		{
			maxHeight : '36px'
		},
		customRoomUrlInput :
		{
			width : '60%'
		}
	});

const logger = new Logger('AddressBookDialog');

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding : theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const urlPattern = new RegExp('^(https?:\\/\\/)?'+
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
	'((\\d{1,3}\\.){3}\\d{1,3}))'+
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
	'(\\?[;&a-z\\d%_.~+=-]*)?'+
	'(\\#[-a-z\\d_]*)?$', 'i');

const AddressBookDialog = (props) =>
{
	const {
		classes,
		addressBook,
		shutdownEnabled,
		shutdownOpen,
		setShutdownOpen,
		roomClient,
		locale,
		localesList
	} = props;

	const location = useLocation();

	const intl = useIntl();

	const [ customRoomUrl, setCustomRoomUrl ] = useState(null);

	const logoURL = config.logo ? config.logo : '';

	// const dummyjson = '{"own":[{"title":"My room","displayName":"Operating room","roomId":"test11","pin":"666"}],"other":[{"title":"PSzCh Kraków","url":"https://video.polskaszkolachirurgii/pszch.krakow.or1"},{"title":"PSzCh Lublin","url":"https://video.polskaszkolachirurgii/pszch.lublin.or1"}]}';

	// const addressBook = JSON.parse(dummyjson);

	// let addressBook;

	if (!addressBook)
		roomClient.getAddressBook();

	if (!shutdownEnabled)
		roomClient.shutdown(null);

	return (
		<div className={classes.root}>
			<Dialog
				open
				maxWidth='xl'
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle>
					{shutdownEnabled &&
					<Grid
						container
						direction='row'
						justifyContent='flex-end'
						alignItems='center'
					>
						<Grid item>
							<Tooltip
								title={intl.formatMessage({
									id             : 'label.shutdownOrRestart',
									defaultMessage : 'Shutdown or restart terminal '
								})}
							>
								<IconButton
									aria-label={intl.formatMessage({
										id             : 'label.shutdownOrRestart',
										defaultMessage : 'Shutdown or restart terminal'
									})}
									className={classes.actionButton}
									color='inherit'
									onClick={() => setShutdownOpen(!shutdownOpen)}
								>
									<PowerSettingsNewIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					}
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							{ logoURL !=='' &&
								<img alt='Logo' src={logoURL} className={classes.logo} />
							}
						</Grid>
						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
							>
								{/* LOCALE SELECTOR */}
								<Grid item>
									<Grid container direction='column' alignItems='center'>
										<Grid item>
											<PopupState variant='popover' popupId='demo-popup-menu'>
												{(popupState) => (
													<React.Fragment>
														<Button
															className={classes.actionButton}
															aria-label={locale.split(/[-_]/)[0]}
															color='secondary'
															disableRipple
															style={{ backgroundColor: 'transparent' }}
															{...bindTrigger(popupState)}
														>
															{locale.split(/[-_]/)[0]}
														</Button>
														<Menu {...bindMenu(popupState)}>
															{localesList.map((item, index) => (
																<MenuItem
																	selected={item.locale.includes(locale)}
																	key={index}
																	onClick={() =>
																	{
																		roomClient.setLocale(item.locale[0]);
																	}}
																>
																	{item.name}
																</MenuItem>)
															)}

														</Menu>
													</React.Fragment>
												)}
											</PopupState>
										</Grid>
									</Grid>
								</Grid>
								{/* /LOCALE SELECTOR */}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								<FormattedMessage
									id='addressBook.title'
									defaultMessage={'Address Book'}
								/>
							</Typography>
						</Grid>
					</Grid>
					<hr />
				</DialogTitle>
				<DialogContent>
					{addressBook && addressBook.own &&
					<ul className={classes.list}>
						<li
							className={classes.listheader}
						>
							<FormattedMessage
								id='addressBook.own'
								defaultMessage='Own room'
							/>
						</li>
						<Flipper>
							{addressBook.own.map((entry) => (
								<Flipped key={entry.title} flipId={entry.title}>
									<li
										key={entry.title}
										className={classnames(classes.listItem)}
									>
										<div className={classes.listItemRoot}>
											<div className={classes.peerInfo}>
												{entry.title}
											</div>
											<Button
												variant='contained'
												color='secondary'
												type='button'
												className={classes.joinButton}
												onClick={() =>
												{
													const entryUrl = (entry.url && entry.url.length > 0) ?
														entry.url : `https://${window.location.hostname}/${entry.roomId}`;

													let paramSep = '?';

													if (entryUrl.includes('?'))
														paramSep = '&';

													window.location.href = `${entryUrl}${paramSep}displayName=${entry.displayName}&pin=${entry.pin}`;
												}}
											>
												<FormattedMessage
													id='label.join'
													defaultMessage='Join'
												/>
											</Button>
										</div>
									</li>
								</Flipped>
							))}
						</Flipper>
					</ul>
					}
					{addressBook && addressBook.other &&
					<ul className={classes.list}>
						<li
							className={classes.listheader}
						>
							<FormattedMessage
								id='addressBook.other'
								defaultMessage='Other rooms'
							/>
						</li>
						<Flipper>
							{addressBook.other.map((entry) => (
								<Flipped key={entry.title} flipId={entry.title}>
									<li
										key={entry.title}
										className={classnames(classes.listItem)}
									>
										<div className={classes.listItemRoot}>
											<div className={classes.peerInfo}>
												{entry.title}
											</div>
											<Button
												variant='contained'
												color='secondary'
												type='button'
												className={classes.joinButton}
												onClick={() =>
												{
													window.location.href = entry.url;
												}}
											>
												<FormattedMessage
													id='label.join'
													defaultMessage='Join'
												/>
											</Button>
										</div>
									</li>
								</Flipped>
							))}
						</Flipper>
					</ul>
					}
					<ul className={classes.list}>
						<li
							className={classes.listheader}
						>
							<FormattedMessage
								id='addressBook.customRoomUrl'
								defaultMessage='Room address'
							/>
						</li>
						<Flipper>
							<Flipped key='customRoom' flipId='customRoom'>
								<li
									key='customRoom'
									className={classnames(classes.listItem)}
								>
									<div className={classes.listItemRoot}>
										<div className={classes.peerInfo}>
											<TextField
												className={classes.customRoomUrlInput}
												id='customRoomUrl'
												value={customRoomUrl}
												onChange={(event) =>
												{
													const { value } = event.target;

													setCustomRoomUrl(value);
												}}
												label={intl.formatMessage({
													id             : 'addressBook.customRoomUrl',
													defaultMessage : 'Room address'
												})}
												variant='outlined'
												margin='normal'
											/>
										</div>
										<Button
											variant='contained'
											color='secondary'
											type='button'
											className={classes.joinButton}
											disabled={!customRoomUrl ||
												!urlPattern.test(customRoomUrl)
											}
											onClick={() =>
											{
												if (customRoomUrl &&
													Boolean(urlPattern.test(customRoomUrl)))
												{
													window.location.href = customRoomUrl;
												}
											}}
										>
											<FormattedMessage
												id='label.join'
												defaultMessage='Join'
											/>
										</Button>
									</div>
								</li>
							</Flipped>
						</Flipper>
					</ul>
				</DialogContent>
			</Dialog>
			{shutdownOpen &&
				<ShutdownDialog/>
			}
		</div>
	);
};

AddressBookDialog.propTypes =
{
	classes         : PropTypes.object.isRequired,
	addressBook     : PropTypes.object.isRequired,
	shutdownEnabled : PropTypes.bool.isRequired,
	shutdownOpen    : PropTypes.bool.isRequired,
	setShutdownOpen : PropTypes.func.isRequired,
	roomClient      : PropTypes.any.isRequired,
	locale          : PropTypes.string.isRequired,
	localesList     : PropTypes.array.isRequired
};

const makeMapStateToProps = (initialState) =>
{
	const mapStateToProps = (state) =>
	{
		return {
			addressBook     : state.me.addressBook,
			shutdownEnabled : state.me.shutdownEnabled,
			shutdownOpen    : state.room.shutdownOpen,
			locale          : state.intl.locale,
			localesList     : state.intl.list
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
	({
		setShutdownOpen : (shutdownOpen) =>
		{
			dispatch(roomActions.setShutdownOpen(shutdownOpen));
		}
	});

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.me.addressBook === next.me.addressBook &&
				prev.me.shutdownEnabled === next.me.shutdownEnabled &&
				prev.room.shutdownOpen === next.room.shutdownOpen &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList
			);
		}
	}
)(withStyles(styles)(AddressBookDialog)));
