export class PreviewFakeProducer
{
	constructor(pid, closeCallback, onStats, track, appData = {})
	{
		this.id = pid;
		this.track = track;
		this.appData = appData;
		this.encodings = [];
		this.codecOptions = {};
		this.codec = 'INTERNAL';
		this.stopTracks = true;
		this.disableTrackOnPause = true;
		this.zeroRtpOnPause = false;
		this.isLocal = true;
		this.onClose = closeCallback;
		this.onStats = onStats;
	}

	async getStats()
	{
		return this.onStats();
	}

	close()
	{
		this.onClose();
	}
}
