import Logger from './Logger';

import * as terminalActions from './store/actions/terminalActions';
import { Client, makeTransport } from '@medvc/core_client';
import { KVM, Main, Sources, Terminal } from '@medvc/terminal_client';

const logger = new Logger('Terminal Client');

let store;

export default class TerminalClient
{

	static init(data)
	{
		store = data.store;
	}

	constructor(
		{
			uri
		} = {})
	{
		this._closed = false;
		this._uri = uri;
		this._keepalive = null;
		this._sources = null;
		this._terminal = null;
		this._kvm = null;
		this._main = null;
		this._client = new Client(makeTransport(uri), {});
		this._client.on('isLive', ({ live }) =>
		{
			store.dispatch(terminalActions.setTerminalState(live));

			if (live)
				this._onLive();
			else
				this._onClear();
		});
	}

	async start(id, endpoint, token, srt)
	{
		if (!this._keepalive)
		{
			this._keepalive = setInterval(async () =>
			{
				await this._terminal.keepalive();
			}, 2000);
		}

		const status = await this._terminal.start(id, endpoint, token, srt);

		store.dispatch(terminalActions.setStatus({ id, status }));

		return status;
	}

	async stop(id)
	{
		const status = await this._terminal.stop(id);

		store.dispatch(terminalActions.setStatus({ id, status }));

		return status;
	}

	async getStats(id)
	{
		const stats = await this._terminal.stats(id);

		return stats;
	}

	async stopAll()
	{
		await this._terminal.stopAll();

		const statuses = await this._terminal.statuses();

		store.dispatch(terminalActions.setStatuses(statuses.statuses));

		return statuses;
	}

	close()
	{
		if (this._closed)
			return;

		this._closed = true;

		this._onClear();

		if (this._keepalive)
			clearInterval(this._keepalive);

		this._client.close();

		this._uri = null;
		this._keepalive = null;
		this._sources = null;
		this._terminal = null;
		this._kvm = null;
		this._main = null;
		this._client = null;

		logger.debug('close()');
	}

	_onClear()
	{
		store.dispatch(terminalActions.setTerminalVersion(''));
		store.dispatch(terminalActions.setSources({}));
		store.dispatch(terminalActions.setStatuses({}));
	}

	_onLive()
	{
		try
		{
			this._sources = new Sources(this._client);
			this._terminal = new Terminal(this._client);
			this._kvm = new KVM(this._client);
			this._main = new Main(this._client);

			this._main.version()
				.then((version) =>
				{
					store.dispatch(terminalActions.setTerminalVersion(version));
				})
				.catch((error) =>
				{
					logger.error('Error in [error:"%o"]', error);
				});

			this._sources.init()
				.then((sources) =>
				{
					store.dispatch(terminalActions.setSources(sources.sources));
				})
				.catch((error) =>
				{
					logger.error('Error in [error:"%o"]', error);
				});

			this._terminal.statuses()
				.then((statuses) =>
				{
					store.dispatch(terminalActions.setStatuses(statuses.statuses));
				})
				.catch((error) =>
				{
					logger.error('Error in [error:"%o"]', error);
				});
		}
		catch (error)
		{
			logger.error('Error in [error:"%o"]', error);
		}
	}
}
