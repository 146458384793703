import React, { useState, useEffect } from 'react';
import Logger from '../Logger';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import classnames from 'classnames';
import isElectron from 'is-electron';
import * as settingsActions from '../store/actions/settingsActions';
import * as meActions from '../store/actions/meActions';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CookieConsent from 'react-cookie-consent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import BlockIcon from '@material-ui/icons/Block';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import randomString from 'random-string';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { config } from '../config';
import InfoIcon from '@material-ui/icons/Info';
import { getServerUrl } from '../urlFactory';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${config.background})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width                          : '30vw',
			padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '40vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width  : '90vw',
				margin : 0
			}
		},
		accountButton :
		{
			padding : 0
		},
		accountButtonAvatar :
		{
			width                         : 50,
			height                        : 50,
			[theme.breakpoints.down(400)] :
			{
				width  : 35,
				height : 35
			}

		},
		logo :
		{
			maxHeight : 48,
			height    : 48
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		},
		red :
		{
			color : 'rgba(153, 0, 0, 1)'
		},
		joinButton :
		{
			[theme.breakpoints.down(600)] :
			{
				'width' : '100%'
			}

		},
		roomid :
		{

		},
		mediaDevicesAnySelectedButton :
		{
			'& .Mui-selected' : {
				color           : 'white',
				backgroundColor : '#5F9B2D',
				'&:hover'       : {
					color           : 'white',
					backgroundColor : '#5F9B2D'
				} }

		},

		mediaDevicesNoneSelectedButton :
		{
			'& .Mui-selected' : {
				color           : 'white',
				backgroundColor : '#f50057',
				'&:hover'       : {
					color           : 'white',
					backgroundColor : '#f50057'
				} }

		},

		loginLabel :
		{
			fontSize : '12px'
		}

	});

const logger = new Logger('JoinDialog');

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin        : 0,
		padding       : theme.spacing(1),
		paddingBottom : theme.spacing(0)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding    : theme.spacing(2),
		paddingTop : theme.spacing(0)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

let isPinLogin = false;

let isUIRecorder = false;

let shutdownEnabledChecked = false;

let joinClicked = false;

let isAccessToken = false;

let isForceJoin = false;

const JoinDialog = ({
	roomClient,
	room,
	mediaPerms,
	displayName,
	displayNameInProgress,
	joinInProgress,
	loggedIn,
	unauthorized,
	shutdownEnabled,
	browser,
	changeDisplayName,
	setMediaPerms,
	setRecorderMode,
	setVideoForwardConfig,
	classes,
	setAudioMuted,
	setVideoMuted,
	setUnauthorized,
	locale,
	localesList

}) =>
{
	const location = useLocation();

	const history = useHistory();

	const intl = useIntl();

	// ASTAGOR START
	if (config.noGuestDefaultName && displayName.startsWith('Guest'))
	{
		displayName = '';
	}
	// ASTAGOR END
	displayName = displayName.trimLeft();

	const [ authType, setAuthType ] = useState((loggedIn) ? 'auth' : 'guest');

	const [ roomId, setRoomId ] = useState(
		decodeURIComponent(location.pathname.slice(1)) ||
		randomString({ length: 8 }).toLowerCase()
	);

	useEffect(() =>
	{
		let uriParams = encodeURIComponent(roomId);

		if (window.location.search.search('overroomlimit=1') > -1)
		{
			uriParams = `${uriParams }?overroomlimit=1`;
		}

		window.history.replaceState({}, null, uriParams || '/');

	}, [ roomId ]);

	useEffect(() =>
	{
		(location.pathname === '/') && history.push(encodeURIComponent(roomId));
	});

	/* const _askForPerms = () =>
	{
		if (mediaPerms.video || mediaPerms.audio)
		{
			navigator.mediaDevices.getUserMedia(mediaPerms);
		}
	}; */

	const handleSetMediaPerms = (event, newMediaPerms) =>
	{

		if (newMediaPerms !== null)
		{
			setMediaPerms(JSON.parse(newMediaPerms));
		}
	};

	const handleSetAuthType = (event, newAuthType) =>
	{
		if (newAuthType !== null)
		{
			setAuthType(newAuthType);
		}

	};

	const handleJoin = () =>
	{
		if (joinClicked)
			return;
		else
			joinClicked = true;

		setAudioMuted(false);

		setVideoMuted(false);

		// _askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		roomClient.join({
			roomId    : encodedRoomId,
			joinVideo : mediaPerms.video,
			joinAudio : mediaPerms.audio
		});
	};

	const handleFocus = (event) =>
	{
		// ASTAGOR START
		let { value } = event.target;

		value = value.trim();
		if (!config.noGuestDefaultName && value === '')
			changeDisplayName(`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
		// ASTAGOR END

		event.target.select();
	};

	/*
	const handleAuth = () =>
	{
		_askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		!loggedIn ?
			roomClient.login(encodedRoomId) :
			roomClient.join({
				roomId    : encodedRoomId,
				joinVideo : mediaPerms.video,
				joinAudio : mediaPerms.audio
			});

	};
	*/

	const handleJoinUsingEnterKey = (event) =>
	{
		if (event.key === 'Enter') document.getElementById('joinButton').click();
	};

	const handleChangeDisplayName = (event) =>
	{
		const { key } = event;

		switch (key)
		{
			case 'Enter':
			case 'Escape':
			{
				// ASTAGOR START
				const value = displayName.trim();

				if (!config.noGuestDefaultName && value === '')
					changeDisplayName(`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
				if (value !== '' && room.inLobby)
					roomClient.changeDisplayName(value);
				if (value !== '' && !room.inLobby)
				{
					changeDisplayName(value);
				}
				// ASTAGOR END
				break;
			}
			default:
				break;
		}
	};

	const logoURL = (config.logos && config.logos[roomId])?config.logos[roomId]:(config.logo?config.logo:'');

	const roomTitle = (config.titles && config.titles[roomId])? config.titles[roomId]:(config.title ? config.title : 'Video Rooms');
	const params = new URLSearchParams(window.location.search);

	const serverUrl = getServerUrl();

	/*
	Typy recorderów
	1 - rzeczywisty automatyczny recorder medVC 1.5
	2 - jak 1 tylko ręczne wejście przez join, widok na projektor
	3 - widok grid tylko 1 Peera, np. jak przekazujemy 3 obrazy z 1 sali operacyjnej
	4 - widok grid wszystkich Peerów. Zawsze Grid, nie reaguje na fullscreen spotlight, etc.
	5 - jak 2, ale nie robi follow właściciela, terminal mix na eventy dla moderatorów
	6 - widok dla 1 video na full, aby do Vmix wysłać
	34 - jak 3 ale jak nie ma wybranego peera przechodzina 43 (nie startujemy nigdy z tej opcji tylko z 43)
	43 - jak 4, ale reaguje na komendy i jak wybierzemy peera, to przechodzi na 34
		(auto join - uwaga musimy dodać opcję do przeglądarki aby auto play audio działało)
	*/

	if (params.has('isrecorder') && params.get('isrecorder') === '2')
	{
		isUIRecorder = true;
		setMediaPerms({ 'audio': false, 'video': false });
	}
	else if (params.has('isrecorder') && params.get('isrecorder') === '3')
	{
		setRecorderMode(3);
		isUIRecorder = true;
		setMediaPerms({ 'audio': false, 'video': false });
	}
	else if (params.has('isrecorder') && params.get('isrecorder') === '4')
	{
		setRecorderMode(4);
		isUIRecorder = true;
		setMediaPerms({ 'audio': false, 'video': false });
	}
	else if (params.has('isrecorder') && params.get('isrecorder') === '5')
	{
		setRecorderMode(5);
		isUIRecorder = true;
		setMediaPerms({ 'audio': false, 'video': false });
	}
	else if (params.has('isrecorder') && params.get('isrecorder') === '43')
	{
		setRecorderMode(43);
		isUIRecorder = false;
		// do not set media params for no UI recorder
		// setMediaPerms({ 'audio': false, 'video': false });
	}
	else if (params.has('isrecorder') && params.get('isrecorder') === '6')
	{
		const clientId = params.get('clientId');
		const videoId = params.get('videoId');
		const noLogo = (params.get('noLogo') === '1');
		const noName = (params.get('noName') === '1');

		let forceExtraVideo = false;

		if (params.get('preferExtraVideo') === '1')
			forceExtraVideo = true;

		if (!clientId || !videoId)
		{
			window.location.replace('/401.html');
		}

		setRecorderMode(6);
		setVideoForwardConfig({ clientId, videoId, forceExtraVideo, noLogo, noName });
	}

	if (!room.inLobby && window.location.search.search('forcejoin=1') > -1)
	{
		isForceJoin = true;

		if (!joinClicked)
		{
			joinClicked = true;
			roomClient.join({ roomId, joinVideo: true, joinAudio: true });
		}
	}

	if (!room.inLobby && !isForceJoin && window.location.search.search('pin=') > -1)
	{

		if (params.has('pin'))
		{
			isPinLogin = true;

			const isRecorder = params.has('isrecorder');

			const callbackParams = {
				'peerId'     : roomClient._peerId,
				'roomId'     : roomId,
				'isRecorder' : isRecorder ? '1' : '0',
				'username'   : `${window.location.hostname}|SPLIT|${roomId}`,
				'password'   : params.get('pin')
			};

			const formBody = Object.keys(callbackParams).map((key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(callbackParams[key])}`)
				.join('&');

			fetch(`${serverUrl}/auth/callback_pin`, {
				headers : {
					'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8'
				},
				credentials    : 'include',
				method         : 'POST',
				cache          : 'no-cache',
				redirect       : 'follow',
				body           : formBody,
				referrerPolicy : 'no-referrer' })
				.then((response) => response.json())
				.then((json) =>
				{
					if (json.loggedIn)
					{
						if (isRecorder)
						{
							roomClient.setLoggedIn(json.loggedIn, false, true);

							if (isUIRecorder)
								setUnauthorized(false);
							else
								roomClient.join({ roomId, joinVideo: false, joinAudio: false });
						}
						else
						{
							if (!displayName || displayName === '')
								changeDisplayName(intl.formatMessage({
									id             : 'room.defaultOperatingRoomName',
									defaultMessage : 'Operating room'
								}));

							roomClient.setLoggedIn(json.loggedIn, true, false);

							if (browser.isPwa)
								setUnauthorized(false);
							else
								roomClient.join({ roomId, joinVideo: true, joinAudio: true });
						}
					}
					else
					{
						logger.error('Failed to log in automatically.');
						setTimeout(() => { window.location.reload(true); }, 1000);
					}
				})
				.catch((error) =>
				{
					logger.error('Error while performing automatic login.', error);
					setTimeout(() => { window.location.reload(true); }, 1000);
				});
		}
	}

	if (!isPinLogin && !isForceJoin && !room.inLobby &&
		window.location.search.search('access_token=') > -1)
	{
		if (params.has('access_token'))
		{
			isAccessToken = true;

			const callbackParams = {
				'roomId'      : roomId,
				'accessToken' : params.get('access_token')
			};

			const formBody = Object.keys(callbackParams).map((key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(callbackParams[key])}`)
				.join('&');

			fetch(`${serverUrl}/auth/callback_access`, {
				headers : {
					'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8'
				},
				credentials    : 'include',
				method         : 'POST',
				cache          : 'no-cache',
				redirect       : 'follow',
				body           : formBody,
				referrerPolicy : 'no-referrer' })
				.then((response) => response.json())
				.then((json) =>
				{
					if (json.error)
					{
						logger.error('Access token error');
						setUnauthorized(true);
						window.location.replace('/401.html');
					}
					else
					{
						window.location.reload();
					}
				})
				.catch((error) =>
				{
					logger.error('Error while performing access token validation.', error);
					setUnauthorized(true);
					window.location.replace('/401.html');
				});
		}
	}

	if (!isPinLogin && !isAccessToken && !isForceJoin)
	{
		const callbackParams = {
			'roomId' : roomId
		};

		const formBody = Object.keys(callbackParams).map((key) =>
			`${encodeURIComponent(key)}=${encodeURIComponent(callbackParams[key])}`)
			.join('&');

		fetch(`${serverUrl}/auth/check_login_status`, {
			headers : {
				'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8'
			},
			credentials    : 'include',
			method         : 'POST',
			cache          : 'no-cache',
			redirect       : 'follow',
			body           : formBody,
			referrerPolicy : 'no-referrer' })
			.then((response) => response.json())
			.then((json) =>
			{
				if (json.loggedIn)
				{
					roomClient.setLoggedIn(json.loggedIn);
				}

				if (!json.hasAccessToken && !json.loggedInWithPin)
				{
					setUnauthorized(true);
				}
				else
				{
					setUnauthorized(false);
				}
			})
			.catch((error) =>
			{
				logger.error('Error checking login status', error);
			});
	}

	if (!shutdownEnabledChecked)
	{
		shutdownEnabledChecked = true;
		roomClient.shutdown(null);
	}

	let showDialog = true;

	if (isPinLogin || isAccessToken || (isForceJoin && !room.inLobby))
		showDialog = false;

	// nadpisujemy dla specjalnego recordera view
	if (isPinLogin && isUIRecorder)
		showDialog = true;

	// nadpisujemy dla PWA
	if (isPinLogin && browser.isPwa)
		showDialog = true;

	return (
		<div className={classes.root}>
			{ showDialog &&
			<Dialog
				onKeyDown={handleJoinUsingEnterKey}
				open
				classes={{
					paper : classes.dialogPaper
				}}
			>

				<DialogTitle className={classes.dialogTitle}>
					{shutdownEnabled &&
					<Grid
						container
						direction='row'
						justifyContent='flex-end'
						alignItems='center'
					>
						<Grid item>
							<Button
								onClick={() =>
								{
									window.location = '/address_book';
								}}
								variant='contained'
								color='secondary'
								size='medium'
								fullWidth
							>
								<FormattedMessage
									id='addressBook.title'
									defaultMessage='Address Book'
								/>
							</Button>
						</Grid>
					</Grid>
					}
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							{ logoURL !=='' &&
								<img alt='Logo' src={logoURL} className={classes.logo} />
							}
						</Grid>

						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
							>

								{/* LOCALE SELECTOR */}
								<Grid item>

									<Grid container direction='column' alignItems='center'>
										<Grid item>
											<PopupState variant='popover' popupId='demo-popup-menu'>
												{(popupState) => (
													<React.Fragment>
														<Button
															className={classes.actionButton}
															aria-label={locale.split(/[-_]/)[0]}
															color='secondary'
															disableRipple
															style={{ backgroundColor: 'transparent' }}
															{...bindTrigger(popupState)}
														>
															{locale.split(/[-_]/)[0]}
														</Button>
														<Menu {...bindMenu(popupState)}>
															{localesList.map((item, index) => (
																<MenuItem
																	selected={item.locale.includes(locale)}
																	key={index}
																	onClick={() =>
																	{
																		roomClient.setLocale(item.locale[0]);
																		// handleMenuClose();
																	}}
																>
																	{item.name}
																</MenuItem>)
															)}

														</Menu>
													</React.Fragment>
												)}
											</PopupState>
										</Grid>

										{ config.loginEnabled &&
										<Grid item>
											<div className={classes.loginLabel}>&nbsp;</div>
										</Grid>
										}

									</Grid>

								</Grid>
								{/* /LOCALE SELECTOR */}

								{/* LOGIN BUTTON */}
								{/* config.loginEnabled &&
								<Grid item>
									<Grid container direction='column' alignItems='center'>
										<Grid item>
											<IconButton
												className={classes.accountButton}
												onClick={
													loggedIn ?
														() => roomClient.logout(roomId) :
														() => roomClient.login(roomId)
												}
											>
												<AccountCircle
													className={
														classnames(
															classes.accountButtonAvatar,
															loggedIn ? classes.green : null
														)
													}
												/>
											</IconButton>
										</Grid>
										<Grid item>
											<div className={classes.loginLabel}>
												<FormattedMessage
													id={loggedIn ? 'label.logout' : 'label.login'}
													defaultMessage={loggedIn ? 'Logout' : 'Login'}
												/>
											</div>
										</Grid>
									</Grid>

								</Grid>
								*/
								}
								{/* /LOGIN BUTTON */}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'> {roomTitle} </Typography>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent>
					<hr />
					{/* DEMO INFO*/}
					{(window.location.hostname === 'public-demo.video-rooms.de') &&
						<DialogContentText className={classes.red} variant='h5' gutterBottom>
							<FormattedMessage
								id='room.demoJoinMessage'
								defaultMessage={
									'To enter the demo room, please use the login button located above and use the PIN: 12345'
								}
							/>
						</DialogContentText>
					}
					{/* ROOM NAME */}
					<TextField
						className={classes.roomid}
						id='roomId'
						label={intl.formatMessage({
							id             : 'label.roomName',
							defaultMessage : 'Room name'
						})}
						value={roomId}
						variant='outlined'
						margin='normal'
						InputProps={{
							readOnly       : true,
							startAdornment : (
								<InputAdornment position='start'>
									<MeetingRoomIcon />
								</InputAdornment>
							)
						}}
						onChange={(event) =>
						{
							const { value } = event.target;

							setRoomId(value.toLowerCase());

						}}
						onFocus={handleFocus}
						onBlur={() =>
						{
							if (roomId === '')
								setRoomId(randomString({ length: 8 }).toLowerCase());
						}}
						fullWidth
						autoComplete='off'
					/>
					{/* /ROOM NAME */}

					{/* AUTH TOGGLE BUTTONS */}
					{false &&
					<Grid container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<ToggleButtonGroup
								value={authType}
								onChange={handleSetAuthType}
								aria-label='choose auth'
								exclusive
							>
								<ToggleButton value='guest'>
									<WorkOutlineIcon/>&nbsp;

									<FormattedMessage
										id='label.guest'
										defaultMessage='Guest'
									/>
								</ToggleButton>

								<ToggleButton value='auth'>
									<VpnKeyIcon/>&nbsp;

									<FormattedMessage
										id='label.auth'
										defaultMessage='Auth'
									/>
								</ToggleButton>

							</ToggleButtonGroup >

						</Grid>

					</Grid>
					}
					{/* /AUTH TOGGLE BUTTONS */}

					{/* NAME FIELD */}
					<TextField
						autoFocus
						id='displayname'
						label={intl.formatMessage({
							id             : 'label.yourName',
							defaultMessage : 'Your name'
						})}
						value={displayName}
						variant='outlined'
						onFocus={handleFocus}

						InputProps={{
							startAdornment : (
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							)
						}}

						margin='normal'
						disabled={displayNameInProgress}
						onChange={(event) =>
						{
							const { value } = event.target;

							changeDisplayName(value);
						}}
						onKeyDown={handleChangeDisplayName}
						onBlur={(event) =>
						{
							// ASTAGOR START
							let { value } = event.target;

							value = value.trim();
							if (!config.noGuestDefaultName && value === '')
								changeDisplayName(`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
							if (value !== '' && room.inLobby)
								roomClient.changeDisplayName(value);
							if (!room.inLobby)
							{
								changeDisplayName(value);
							}
							// ASTAGOR END
						}}
						fullWidth
					/>

					{/* NAME FIELD*/}

					{!room.inLobby &&
					// ASTAGOR START
					// room.overRoomLimit &&
					(room.overRoomLimit ||
					(window.location.search.search('overroomlimit=1') > -1)) &&
					// ASTAGOR END
						<DialogContentText className={classes.red} variant='h6' gutterBottom>
							<FormattedMessage
								id='room.overRoomLimit'
								defaultMessage={
									'The room is full, retry after some time.'
								}
							/>
						</DialogContentText>
					}
					{!room.inLobby &&
					((room.locked && !loggedIn) ||
					(window.location.search.search('roomLockedPeerNotLoggedIn=1') > -1)) &&
						<DialogContentText className={classes.red} variant='h6' gutterBottom>
							<FormattedMessage
								id='room.lockedRetry'
								defaultMessage={
									'The room is locked, please contact the organizer.'
								}
							/>
						</DialogContentText>
					}
				</DialogContent>

				{ !room.inLobby ?

					<DialogActions>

						<Grid container
							direction='row'
							justifyContent='space-between'
							alignItems='flex-end'
							spacing={1}
						>

							{/* MEDIA PERMISSIONS TOGGLE BUTTONS */}

							<Grid item>
								<FormControl component='fieldset'>
									<Box mb={1}>
										<FormLabel component='legend'>
											<FormattedMessage
												id='devices.chooseMedia'
												defaultMessage='Choose Media'
											/>
										</FormLabel>
									</Box>
									<ToggleButtonGroup
										value={JSON.stringify(mediaPerms)}
										size='small'
										onChange={handleSetMediaPerms}
										className={
											JSON.stringify(mediaPerms) ===
											'{"audio":false,"video":false}' ?
												classes.mediaDevicesNoneSelectedButton :
												classes.mediaDevicesAnySelectedButton
										}
										aria-label='choose permission'
										exclusive
									>
										<ToggleButton value='{"audio":false,"video":false}'>
											<Tooltip title={intl.formatMessage({
												id             : 'devices.disableBothMicrophoneAndCamera',
												defaultMessage : 'Disable both Microphone and Camera'
											})} placement='bottom'
											>
												<BlockIcon/>
											</Tooltip>
										</ToggleButton>
										<ToggleButton value='{"audio":true,"video":false}'>
											<Tooltip title={intl.formatMessage({
												id             : 'devices.enableOnlyMicrophone',
												defaultMessage : 'Enable only Microphone'
											})} placement='bottom'
											>

												<MicIcon/>
											</Tooltip>
										</ToggleButton>
										<ToggleButton value='{"audio":false,"video":true}'>
											<Tooltip title={intl.formatMessage({
												id             : 'devices.enableOnlyCamera',
												defaultMessage : 'Enable only Camera'
											})} placement='bottom'
											>
												<VideocamIcon/>
											</Tooltip>
										</ToggleButton>
										<ToggleButton value='{"audio":true,"video":true}'>
											<Tooltip title={intl.formatMessage({
												id             : 'devices.enableBothMicrophoneAndCamera',
												defaultMessage : 'Enable both Microphone and Camera'
											})} placement='bottom'
											>
												<span style={{ display: 'flex', flexDirection: 'row' }}>
													<MicIcon/>+<VideocamIcon/>
												</span>
											</Tooltip>
										</ToggleButton>
									</ToggleButtonGroup >
								</FormControl>
							</Grid>

							{/* /MEDIA PERMISSION BUTTONS */}

							{/* JOIN/AUTH BUTTON */}
							<Grid item className={classes.joinButton}>
								<Button
									onClick={handleJoin}
									disabled={joinInProgress || room.overRoomLimit ||
										displayName === '' ||
										unauthorized ||
										(room.locked && !loggedIn) ||
										(window.location.search.search('roomLockedPeerNotLoggedIn=1') > -1)
									}
									variant='contained'
									color='secondary'
									size='large'
									id='joinButton'
									fullWidth
								>
									{unauthorized &&
										<FormattedMessage
											id='label.unauthorized'
											defaultMessage='Unauthorized'
										/>
									}
									{!unauthorized && displayName === '' &&
									<FormattedMessage
										id='label.enterName'
										defaultMessage='Enter your name'
									/>
									}
									{!unauthorized && displayName !== '' &&
									<FormattedMessage
										id='label.join'
										defaultMessage='Join'
									/>
									}
								</Button>

							</Grid>
							{ config.infoTooltipText!=='' &&

								<div className={classes.infoToolTip}
									style={{
										'padding-top' : '20px',
										'overflowX'   : 'auto',
										'width'       : '100%',
										'display'     : 'flex',
										'align-items' : 'center'
									}}
								>
									<InfoIcon />
									{ config.infoTooltipLink!=='' &&
									<a
										style={{
											'text-decoration' : 'none',
											'padding-left'    : '5px'
										}}
										href={config.infoTooltipLink}
									>{config.infoTooltipText}</a>
									}

									{ config.infoTooltipLink==='' &&
										<p style={{
											'text-decoration' : 'none',
											'padding-left'    : '5px'
										}}
										>{config.infoTooltipText}</p>
									}
								</div>
							}
							{ config.infoTooltipDesc!=='' &&
							<div
								className={classes.infoToolTip}
								style={{
									'padding-top' : '15px',
									'overflowX'   : 'auto',
									'width'       : '100%',
									'display'     : 'flex',
									'align-items' : 'center'
								}}
							>
								{config.infoTooltipDesc}
							</div>
							}
							{/*
							{authType === 'auth' && !loggedIn &&
							<Grid item>
								<Button
									onClick={handleAuth}
									variant='contained'
									color='secondary'
									id='joinButton'
								>
									<FormattedMessage
										id='room.login'
										defaultMessage='Next'
									/>
								</Button>

							</Grid>
							}
							{authType === 'auth' && loggedIn &&
							<Grid item>
								<Button
									onClick={handleJoin}
									variant='contained'
									className={classes.joinButton}
									id='joinButton'
								>
									<FormattedMessage
										id='room.login'
										defaultMessage='Join'
									/>
								</Button>

							</Grid>
							}
							*/}

							{/* /JOIN BUTTON */}

						</Grid>

					</DialogActions>
					:
					<DialogContent>
						<DialogContentText
							className={classes.green}
							gutterBottom
							variant='h6'
							style={{ fontWeight: '600' }}
							align='center'
						>
							<FormattedMessage
								id='room.youAreReady'
								defaultMessage='Ok, you are ready'
							/>
						</DialogContentText>
						{ room.signInRequired ?
							<DialogContentText
								gutterBottom
								variant='h5'
								style={{ fontWeight: '600' }}
							>
								<FormattedMessage
									id='room.emptyRequireLogin'
									defaultMessage={
										`The room is empty! You can Log In to start
										the meeting or wait until the host joins`
									}
								/>
							</DialogContentText>
							:
							<DialogContentText
								gutterBottom
								variant='h5'
								style={{ fontWeight: '600' }}
							>
								<FormattedMessage
									id='room.locketWait'
									defaultMessage='The room is locked - hang on until somebody lets you in ...'
								/>
							</DialogContentText>
						}
					</DialogContent>
				}

				{ false && !isPinLogin && !isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
				}
			</Dialog>
			}
		</div>
	);
};

JoinDialog.propTypes =
{
	roomClient            : PropTypes.any.isRequired,
	room                  : PropTypes.object.isRequired,
	roomId                : PropTypes.string.isRequired,
	displayName           : PropTypes.string.isRequired,
	displayNameInProgress : PropTypes.bool.isRequired,
	joinInProgress        : PropTypes.bool.isRequired,
	loginEnabled          : PropTypes.bool.isRequired,
	loggedIn              : PropTypes.bool.isRequired,
	unauthorized          : PropTypes.bool.isRequired,
	shutdownEnabled       : PropTypes.bool.isRequired,
	browser               : PropTypes.object.isRequired,
	changeDisplayName     : PropTypes.func.isRequired,
	setMediaPerms         : PropTypes.func.isRequired,
	classes               : PropTypes.object.isRequired,
	mediaPerms            : PropTypes.object.isRequired,
	setAudioMuted         : PropTypes.func.isRequired,
	setVideoMuted         : PropTypes.func.isRequired,
	setUnauthorized       : PropTypes.func.isRequired,
	setRecorderMode       : PropTypes.func.isRequired,
	setVideoForwardConfig : PropTypes.func.isRequired,
	locale                : PropTypes.string.isRequired,
	localesList           : PropTypes.array.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room                  : state.room,
		mediaPerms            : state.settings.mediaPerms,
		displayName           : state.settings.displayName,
		displayNameInProgress : state.me.displayNameInProgress,
		joinInProgress        : state.me.joinInProgress,
		loginEnabled          : state.me.loginEnabled,
		loggedIn              : state.me.loggedIn,
		unauthorized          : state.me.unauthorized,
		shutdownEnabled       : state.me.shutdownEnabled,
		browser               : state.me.browser,
		locale                : state.intl.locale,
		localesList           : state.intl.list

	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		changeDisplayName : (displayName) =>
		{
			dispatch(settingsActions.setDisplayName(displayName));
		},

		setMediaPerms : (mediaPerms) =>
		{
			dispatch(settingsActions.setMediaPerms(mediaPerms));
		},
		setAudioMuted : (flag) =>
		{
			dispatch(settingsActions.setAudioMuted(flag));
		},
		setVideoMuted : (flag) =>
		{
			dispatch(settingsActions.setVideoMuted(flag));
		},
		setUnauthorized : (flag) =>
		{
			dispatch(meActions.setUnauthorized(flag));
		},
		setRecorderMode : (recorderMode) =>
		{
			dispatch(meActions.setRecorderMode(recorderMode));
		},
		setVideoForwardConfig : (videoForwardConfig) =>
		{
			dispatch(meActions.setVideoForwardConfig(videoForwardConfig));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.settings.displayName === next.settings.displayName &&
				prev.settings === next.settings &&
				prev.me.displayNameInProgress === next.me.displayNameInProgress &&
				prev.me.joinInProgress === next.me.joinInProgress &&
				prev.me.loginEnabled === next.me.loginEnabled &&
				prev.me.loggedIn === next.me.loggedIn &&
				prev.me.unauthorized === next.me.unauthorized &&
				prev.me.shutdownEnabled === next.me.shutdownEnabled &&
				prev.me.picture === next.me.picture &&
				prev.me.browser === next.me.browser &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList

			);
		}
	}
)(withStyles(styles)(JoinDialog)));
